.tabs {
  display: flex;
  flex-direction: column;
  gap: var(--space-between-m);
}

.tab-full-width {
  a {
    max-width: unset;
  }
}
